<template>
    <div class="breadcrumb">
        <ul>
            <li>
                <router-link to="/">Home</router-link>
            </li>
            <li>
                properties
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: 'Breadcrumb',
}
</script>