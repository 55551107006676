<template>
    <div class="content-part property-list">
        <div class="container">
            <div class="row">
                <div class="col-xl-6">
                    <div class="actionLeft">
                        <ul>
                            <li>Showing {{ from }} – {{ properties.length }} of {{ properties.length }} results</li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="shorting-list">
                        <span>Short By:</span>
                        <select v-model="filterData.sort" @change="onChange($event)" >
                            <option value="null">Default</option>
                            <option value="latest">Newest</option>
                            <option value="priceasc">Price low to high</option>
                            <option value="pricedesc">Price high to low</option>
                        </select>
                    </div>
                </div>
            </div>
            <template  v-if="dataStatus">
            <div class="row">
                <div class="col-xl-3 mt-4" v-for="(item,i) in properties" :key="i">
                    <ItemList :item="item" />
                </div>
            </div>
            </template>
        </div>

    </div>
</template>
<script>
import axios from 'axios'
import api from '@/services/api'
import 'vue2-datepicker/index.css'
import ItemList from '@/components/ItemList'
import Helper from "@/services/helper"
export default {
    name: "AdsPropertyList",
    components: { 
        ItemList,
    },
    data() {
        return {
            properties:[],
            from:0,
            filterData:{
                sort:this.$route.query.sort?this.$route.query.sort:null,
            },
            slug:this.$route.params.slug,
            dataStatus: true,
        };        
    },
    mounted() {
        let app = this;
        app.renderProperty();
    },
    methods: {
        async renderProperty(filters =[]){
            let app = this;
            Helper.showSpinner();
            let url;
            if (filters) {
                let filterUrl = Helper.objToUrlParams(filters);
                url = '/ads/properties/'+app.slug+'?'+filterUrl;
            } else {
                url = '/ads/properties/'+app.slug;
            }
            await axios.get(api.getUrl(url)).then(function (response) {
                let responseData        = response.data.data;
                if(response.data.success){
                    app.dataStatus = true;
                    app.from = 1;
                    app.properties     = responseData.lists;
                    app.$emit('totla-properties' , responseData.total);
                }else{
                    app.dataStatus = false;
                    app.from = 0;
                    app.properties     = [];
                    app.$emit('totla-properties' , 0);
                }
            }).finally(() => {
                Helper.hideSpinner();
            });
        },
        filter($event) {
            this.renderProperty($event);
        },
        onChange(event){
            let app = this;
            app.filterData.sort = event.target.value;
            app.renderProperty(app.filterData);
        }
    },
    created() {
        this.$watch(() => this.$route.params,(newParam) => {
            this.slug = newParam.slug,
            this.renderProperty();
        })
    },
}
</script>