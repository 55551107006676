<template>
    <section class="page-wrap">
        <div class="inner-banner">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h1>List of {{ this.$route.params.slug }} Properties</h1>
                        <p>We've more than {{ total_properties }} properties</p>
                    </div>
                    <div class="col-12">
                        <Breadcrumb />
                    </div>
                </div>
            </div>        
        </div>
        <AdsPropertyList @totla-properties="handleTotalProperties($event)" />
    </section>
</template>
<script>
import AdsPropertyList from './partials/AdsPropertyList.vue'
import Breadcrumb from '@/components/Breadcrumb'
export default {
    name: "AdsPropertyListing",
    components:{
        AdsPropertyList,
        Breadcrumb,
    },
    data() {
        return {
            total_properties:"",
        }
    },
    methods: {
        handleTotalProperties($event){
            let app = this;
            app.total_properties = $event;
        }
    }
}
</script>
<style src="../../assets/css/property.css"></style>